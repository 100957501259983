import { toLegalNum, toFixed2 } from "./scientific";
import { queryWorkMessage } from "@/api/calculator/calculator2";
import { Message } from 'element-ui'
import store from "@/store";
import { getUUID } from "./uuid";

/**
 * @description 列表计算总价
 * @param {*} list 要进行价格计算的列表
 * @param {*} field 价格字段名
 * @param {*} mulByNum 是否乘以数量
 * @param {*} numAlias 数量字段，默认为num
 * @returns 计算结果
 */
export const reduceStatistics = (list = []) => {
  return (field) => {
    return ({ mulByNum = false, numAlias = "num" } = {}) => {
      if (list && list.length) {
        return list.reduce((sum, item) => {
          const price = mulByNum ? (item[field] || 0) * item[numAlias] : (item[field] || 0);
          return price ? toLegalNum(sum) + toLegalNum(price) : toLegalNum(sum)
        }, 0);
      } else {
        return 0
      }
    };
  };
};

/**
 * @description 检测是否为空单品
 * @param {*} sku sku单品对象
 * @returns boolean
 */
export const isEmptySku = (sku) => {
  const { productName, brand, brandModel, description, price, cost, unit } = sku
  if (!productName) {
    Message({
      message: '请填写单品名称',
      type: 'error',
    })
    return true
  }
  if (!brand && !brandModel) {
    Message({
      message: '请填写单品品牌型号',
      type: 'error',
    })
    return true
  }
  if (!description) {
    Message({
      message: '请填写单品规格参数',
      type: 'error',
    })
    return true
  }
  if (!price) {
    Message({
      message: '请填写单品报价',
      type: 'error',
    })
    return true
  }
  if (!cost) {
    Message({
      message: '请填写单品成本',
      type: 'error',
    })
    return true
  }
  if (!unit) {
    Message({
      message: '请填写单品单位',
      type: 'error',
    })
    return true
  }
  return false
};

/**
 * @description 自定义单品计算一个唯一的id，用于页面循环key渲染，防止sortable渲染错乱
 * @param {*}
 * @returns
 */
export const getUniqCustomId = () => {
  return getUUID();
};

/**
 * @description 创建一个自定义单品
 * @param {*} groupKey 单品分类字段名，所在展项或集成智能化分类
 * @param {*} groupId 单品分类id
 * @param {*} existSkuList
 * @param {*} skuCustomSettings
 * @returns
 */
export const createCustomSku = (
  extraParams = {},
  skuCustomSettings = { priceLock: false }
) => {
  const customId = getUniqCustomId();
  const skuTemplate = {
    classificationId: 0,
    classification: "",
    name: "",
    productName: "",
    brandModel: "",
    description: "",
    unit: "",
    cost: 0,
    price: "",
    num: 1,
    skuId: 0,
    customId,
    remark: '123',
    ...extraParams,
    ...skuCustomSettings,
  };
  return skuTemplate;
};

/**
 *
 * @param {*} extraParams 其他参数
 * @param {*} existSkuList 当前已有单品
 * @param {*} skuCustomSettings
 * @returns
 */
export const createCustomWork = (
  extraParams = {},
  skuCustomSettings = { priceLock: false }
) => {
  const customId = getUniqCustomId();
  const workTemplate = {
    customId,
    productClassificationId: 0,
    productClassificationName: '',
    specificationId: 0,
    specificationName: "",
    specificationOptionId: 0,
    specificationOptionName: "",
    remark: "",
    price: "",
    cost: "",
    ...extraParams,
    ...skuCustomSettings,
  };
  return workTemplate;
};

/**
 * @description 单品列表查询，列表预处理
 * @param {*} interfaceSkuList 接口返回列表，被预处理的列表
 * @param {*} appendSkuList 本轮选择框中选中的单品列表，如果选中，用于覆盖当前数量
 * @param {*} existSkuList 当前同组（同展项、同智能化集成分组）已存在的单品列表，如果选中，用于覆盖当前数量
 * @returns
 */
export const getPreprocessedSkuList =
  (interfaceSkuList,
    existSkuList = [],
    appendSkuList = [],
    newAdjust = 1,
    otherParams = { multimediaCategoryId: 1, multimediaCategoryName: '硬件设备' }
  ) => {
    // 当前匡算下的所有单品列表
    const multimediaCategoryId = otherParams.multimediaCategoryId;
    const isAssociate = otherParams.isAssociate;

    const globalSkuList =
      multimediaCategoryId == 1
        ? store.getters.globalHardSkuList ?? []
        : store.getters.globalSoftSkuList ?? []

    const skuList = interfaceSkuList && interfaceSkuList.map((skuItem) => {
      // 单品skuId替换(接口返回的id就是skuId)
      skuItem.skuId = skuItem.id;
      skuItem.isSelected = false
      // skuItem.brandModel = skuItem.brand
      Object.assign(skuItem, otherParams);

      // 数量计算
      const skuSameExist = existSkuList.find((es) => es.id == skuItem.id);
      const skuSameAppend = appendSkuList.find((as) => as.id == skuItem.id);

      // console.log("skuSameExist, skuSameAppend: ", skuSameExist, skuSameAppend)

      let num = skuSameAppend
        ? skuSameAppend.num
        : skuSameExist
          ? skuSameExist.num
          : 1;

      num = isAssociate ? skuItem.num : num;

      // console.log("num: ", isAssociate, skuItem.num, num)

      let skuSameGlobal = {}
      if (globalSkuList && globalSkuList.length > 0) {
        // 初始价格计算
        skuSameGlobal = globalSkuList.find((gs) => gs.id == skuItem.id);
      }

      // 价格先按当前比例浮动
      const defaultPrice = skuItem.price
      skuItem.price = toFixed2(skuItem.price * newAdjust);

      // 查询价格是否手动调节过，替换
      const price = skuSameAppend
        ? skuSameAppend.price
        : skuSameGlobal && skuSameGlobal.priceLock
          ? skuSameGlobal.price
          : skuItem.price;

      const skuPreprocessed = {
        ...skuItem,
        defaultPrice, // 存储默认价格
        num, // 数量 -- 优先级：本轮选择框选中单品数量覆盖 > 同组已选单品数量覆盖 > 默认为 1
        price, // 价格 -- 优先级：本轮选择框选中单品价格覆盖 > 全局单品已选且锁价价格覆盖 > 默认为单品本身价格
        priceLock: (skuSameGlobal && skuSameGlobal.priceLock) ?? false, // 是否锁定价格
        replaceList: [], // 设置品牌替换列表，防止渲染错误
        brandLock: false
      };
      return skuPreprocessed;
    });
    return skuList;
  };

/**
 * @description 价格计算
 * @param  {...any} args 多个价格
 * @returns
 */
export const priceAdd = (...args) => {
  let sumPrice = 0;
  for (let i = 0; i < args.length; i++) {
    const price = toLegalNum(args[i]);
    sumPrice += price;
  }
  return toFixed2(sumPrice);
};


/**
 * @description 初始化工作内容配置列表
 * @param {*} interfaceWorkList 接口内容列表
 * @param {*} productClassificationId 所属一级分类
 * @param {*} classificationName 所属一级分类名
 * @param {*} existWorkList 已经存在的内容
 * @param {*} appendWorkList 本次暂存区内容
 * @param {*} newAdjust 新调价比例
 * @param {*} otherParams 其他参数
 * @returns
 */
export const getTreatedConfigList = async (
  interfaceWorkList,
  productClassificationId,
  classificationName,
  existWorkList = [],
  appendWorkList = [],
  newAdjust = 1,
  otherParams = { multimediaCategoryId: 3, multimediaCategoryName: "软件程序" }
) => {
  // console.log('interface叽叽叽叽:', interfaceWorkList)
  const workList = interfaceWorkList.map((workItem) => {

    const workSameExist = existWorkList.find(
      (ew) => (ew.specificationId == workItem.specificationId && ew.productClassificationId == workItem.productClassificationId)
    );
    const workSameAppend = appendWorkList.find(
      (aw) => (aw.specificationId == workItem.specificationId && aw.productClassificationId == workItem.productClassificationId)
    );

    const workSame = workSameAppend || workSameExist;

    const isSelected = !!(workSameExist || workSameAppend);

    // 工作内容参数名称替换
    workSame && (workItem.specificationOptionId = workSame.specificationOptionId);
    workSame && (workItem.specificationOptionName = workSame.specificationOptionName);
    workItem.productClassificationName = classificationName;
    workSame && (workItem.remark = workSame.remark);

    // 其他参数添加
    Object.assign(workItem, otherParams);

    // 数量计算
    const num = workSame ? (workSame.num ?? 1) : 1;

    // 选项价格浮动初始化
    workItem.softSpecificationOptions.map((option) => {
      option.defaultPrice = option.price;
      option.price = toFixed2(option.price * newAdjust);
    });

    // 价格先按当前比例浮动
    workItem.defaultPrice = workItem.price
    workItem.price = toFixed2(workItem.price * newAdjust);

    let workTreated = { ...workItem, isSelected }
    if (workSame) {
      workTreated = {
        ...workTreated,
        defaultPrice: workItem.price, // 存储默认价格
        num, // 数量 -- 优先级：本轮选择框选中数量覆盖 > 同组已选数量覆盖 > 默认为 1
        price: workSame.price,
        cost: workSame.cost,
        lowPrice: workSame.lowPrice,
        highPrice: workSame.highPrice,
        floorPrice: workSame.floorPrice,
        ceilPrice: workSame.ceilPrice,
        unit: workSame.unit,
        priceLock: (workSameExist && workSameExist.priceLock) ?? false,
      };
    }
    return workTreated;
  });

  // 未选择过，列表初次展示未选择的列表；选择过的展示已选择的内容
  const existWorkListCur = existWorkList
    .concat(appendWorkList)
    .filter((item) => item.productClassificationId == productClassificationId);


  const templateWorkMap = [];
  const selectedList = existWorkListCur.map((work, index) => {
    const workOwn = workList.find(
      (item) => item.specificationId == work.specificationId
    );
    if (work.specificationId == 0) {
      // 自定义work
      return work;
    } else if (workOwn) {
      // 常规选择work
      return workOwn;
    } else {
      // 其他分类下选择的work
      templateWorkMap.push({ index, specificationId: work.specificationId });
      return work;
    }
  });

  // 其他分类下的work统一处理，查询选项详情
  if (templateWorkMap.length) {
    const { code, data } = await queryWorkMessage(
      templateWorkMap.map((item) => item.specificationId).join(",")
    );
    if (code === 200) {
      data.map((work) => {
        const { softSpecificationOptions, id } = work;
        softSpecificationOptions.map((option) => {
          option.defaultPrice = option.price;
          option.price = toFixed2(option.price * newAdjust);
        });
        const mapItem = templateWorkMap.find(
          (mp) => mp.specificationId == id
        );
        mapItem &&
          (selectedList[mapItem.index].softSpecificationOptions =
            softSpecificationOptions);
      });
    }
  }
  const defaultList = workList;

  const firstShowList = selectedList.length ? selectedList : defaultList;

  return { workList, firstShowList };
}


/**
 * @description 初始化内容列表，用于弹框选择工作内容，带描述选项
 * @param {*} interfaceWorkList 接口内容列表
 * @param {*} productClassificationId 所属一级分类
 * @param {*} classificationName 所属一级分类名
 * @param {*} existWorkList 已经存在的内容
 * @param {*} appendWorkList 本次暂存区内容
 * @param {*} newAdjust 新调价比例
 * @param {*} otherParams 其他参数
 * @returns
 */
export const getTreatedWorkList = async (
  interfaceWorkList,
  productClassificationId,
  classificationName,
  existWorkList = [],
  appendWorkList = [],
  newAdjust = 1,
  otherParams = { multimediaCategoryId: 3, multimediaCategoryName: "软件程序" }
) => {
  const workList = interfaceWorkList.map((workItem) => {
    // 工作内容参数名称替换
    workItem.specificationId = workItem.id;
    workItem.specificationName = workItem.name;

    const workSameExist = existWorkList.find(
      (ew) => ew.specificationId == workItem.specificationId
    );
    const workSameAppend = appendWorkList.find(
      (aw) => aw.specificationId == workItem.specificationId
    );

    const workSame = workSameAppend || workSameExist || {};

    const isSelected = !!(workSameExist || workSameAppend);

    // 工作内容参数名称替换
    workItem.specificationId = workItem.id;
    workItem.specificationName = workItem.name;
    workItem.specificationOptionId = workSame.specificationOptionId;
    workItem.specificationOptionName = workSame.specificationOptionName;
    workItem.productClassificationName = classificationName;
    workItem.remark = workSame.remark;

    // 其他参数添加
    Object.assign(workItem, otherParams);

    // 数量计算
    const num = workSame.num ?? 1;

    // 选项价格浮动初始化
    workItem.softSpecificationOptions.map((option) => {
      option.defaultPrice = option.price;
      option.price = toFixed2(option.price * newAdjust);
    });

    // 价格先按当前比例浮动
    workItem.price = toFixed2(workItem.price * newAdjust);

    const workTreated = {
      ...workItem,
      defaultPrice: workItem.price, // 存储默认价格
      num, // 数量 -- 优先级：本轮选择框选中数量覆盖 > 同组已选数量覆盖 > 默认为 1
      price: workSame.price ?? 0,
      cost: workSame.cost ?? 0,
      lowPrice: workSame.lowPrice,
      highPrice: workSame.highPrice,
      floorPrice: workSame.floorPrice,
      ceilPrice: workSame.ceilPrice,
      unit: workSame.unit,
      priceLock: (workSameExist && workSameExist.priceLock) ?? false,
      isSelected,
    };
    return workTreated;
  });

  console.log(workList, '???????????????????')

  // 未选择过，列表初次展示未选择的列表；选择过的展示已选择的内容
  const existWorkListCur = existWorkList
    .concat(appendWorkList)
    .filter((item) => item.productClassificationId == productClassificationId);

  const templateWorkMap = [];
  const selectedList = existWorkListCur.map((work, index) => {
    const workOwn = workList.find(
      (item) => item.specificationId == work.specificationId
    );
    if (work.specificationId == 0) {
      // 自定义work
      return work;
    } else if (workOwn) {
      // 常规选择work
      return workOwn;
    } else {
      // 其他分类下选择的work
      templateWorkMap.push({ index, specificationId: work.specificationId });
      return work;
    }
  });

  // 其他分类下的work统一处理，查询选项详情
  if (templateWorkMap.length) {
    const { code, data } = await queryWorkMessage(
      templateWorkMap.map((item) => item.specificationId).join(",")
    );
    if (code === 200) {
      data.map((work) => {
        const { softSpecificationOptions, id } = work;
        softSpecificationOptions.map((option) => {
          option.defaultPrice = option.price;
          option.price = toFixed2(option.price * newAdjust);
        });
        const mapItem = templateWorkMap.find(
          (mp) => mp.specificationId == id
        );
        mapItem &&
          (selectedList[mapItem.index].softSpecificationOptions =
            softSpecificationOptions);
      });
    }
  }
  const defaultList = workList.filter((item) => item.default);

  const firstShowList = selectedList.length ? selectedList : defaultList;
  return { workList, firstShowList };
};

// 价格调整方法
export const priceAdjust = (price) => {
  let value = price;
  const api = {
    // 价格按比例浮动计算方法
    fluctuation: (newAdjust, oldAdjust, lock = false) => {
      if (oldAdjust == 0) {
        return api;
      }
      if (!lock) {
        value = toFixed2((value / oldAdjust) * newAdjust);
      }
      return api;
    },
    // 价格最低最高价限制方法
    restrict: (ceilingPrice, floorPrice) => {
      if (ceilingPrice && value > ceilingPrice) {
        value = ceilingPrice;
      } else if (floorPrice && value < floorPrice) {
        value = floorPrice;
      }
      return api;
    },
    // 获取计算结果
    getValue: () => value,
  };
  return api;
};
